import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { useSpring, animated } from "react-spring";
import "../styles/Questions.css";
import Footer from "../Components/Footer";
import LineBGBlue from "../assets/LineBGBlue.png";
import OrangePartLogo from "../assets/OrangePartLogo.png";
import LinesGreen from "../assets/LinesGreen.png";
import CirculeRed from "../assets/CirculeRed.png";
const createFadeInText = (duration, translateY) => ({ children }) => {
    const props = useSpring({
        opacity: 1,
        transform: `translateY(0)`,
        from: { opacity: 0, transform: `translateY(${translateY}px)` },
        config: { duration },
    });
    return <animated.div style={props}>{children}</animated.div>;
};
const FadeInText = createFadeInText(500, 20);
const FadeInText1 = createFadeInText(600, 20);
const FadeInText2 = createFadeInText(600, 40);
const FadeInText3 = createFadeInText(600, 60);
const FadeInText4 = createFadeInText(600, 70);
const FadeInText5 = createFadeInText(600, 80);
const FadeInText6 = createFadeInText(600, 100);
const FadeInText7 = createFadeInText(600, 120);
export default class Questions extends Component {
    render() {
        return (
            <>
                <Container fluid className="container">
                    <div className="d-inline-block">
                        <p className="titleQuestions mt-3 mb-3 pb-2 border-bottom">Часті запитання</p>
                        <p className="text-start textQuestions">
                            <FadeInText1>
                                <span className="textBoldQuestions">1) Якщо дитина захворіла чи зберігається оплата?</span><br></br>
                                У випадку пропуску заняття, необхідно попередити  адміністратора академії, відпрацювання заняття можливо впродовж двох місяців.<br></br><br></br>
                            </FadeInText1>
                            <FadeInText2>
                                <span className="textBoldQuestions">2) Чи потрібно мати власний комп’ютер/планшет для навчання?</span><br></br>
                                Для офлайн формату академія надає все необхідне технічне обладнання.<br></br>
                                Для заннять в онлайн форматі потрібно мати власний комп’ютер або планшет.<br></br><br></br>
                            </FadeInText2>
                            <FadeInText3>
                                <span className="textBoldQuestions">3) Де я можу дізнатися про знижки або спеціальні акції?</span><br></br>
                                Про всі акції ми повідомляємо на нашому сайті або сторінці в Instagram:
                                <a  className="linkQuestionsA" href="https://www.instagram.com/academy_techkids?igsh=MTEwYTFxMGptMXdyZQ==" target="_blank"><span className="linkInstaQuestions">https://www.instagram.com/academy_techkids/</span></a><br></br><br></br>
                            </FadeInText3>
                            <FadeInText4>
                                <span className="textBoldQuestions">4) Які заходи Ви приймаєте у разі повітряної тривоги?</span><br></br>
                                Академія обладнана приміщенням з трьома стінами та комфортним укртиттям.<br></br><br></br>
                            </FadeInText4>
                            <FadeInText5>
                                <span className="textBoldQuestions">5) Чи потрібен попередній досвід з програмування для навчання у вашій школі?</span><br></br>
                                Наші викладачі навчають дітей з азів та з будь-якого рівня знань.<br></br><br></br>
                            </FadeInText5>
                            <FadeInText6>
                                <span className="textBoldQuestions">6) Яким чином відбувається розрахунок за заняття?</span><br></br>
                                Оплата здійснюється за абонемент (4 заняття) на розрахунковий рахунок.<br></br><br></br>
                            </FadeInText6>
                            <FadeInText7>
                                <span className="textBoldQuestions">7) Чи може дитина під’єднатися до курсу протягом семестру?</span><br></br>
                                Усі наші навчальні програми розраховані таким чином, щоб дитина могла під’єднатись протягом навчального року та швидко адаптуватись в академії.
                            </FadeInText7>
                        </p>
                    </div>
                </Container>
                <img src={LineBGBlue} className="elementQuestions1"></img>
                <img src={OrangePartLogo} className="elementQuestions2"></img>
                <img src={LinesGreen} className="elementQuestions3"></img>
                <img src={CirculeRed} className="elementQuestions4"></img>
                <Footer />
            </>
        )
    }
}