import React, { Component } from "react";
import { Row, Col, Container, Image } from "react-bootstrap"
import "../styles/LoyaltySystem.css";
import Footer from "../Components/Footer";
import LineBGGreen from "../assets/LineBGGreen.png";
import RedPartLogo from "../assets/RedPartLogo.png";
import LoyaltyImg from "../assets/LoyaltyImg.jpg";
import ButtonOnline from "../Components/ButtonOnline";

export default class LoyaltySystem extends Component {
    render() {
        return (
            <>
            <div style={{position: 'absolute', left: '0', bottom: '140px'}}>
                <ButtonOnline />
                </div>
                <Container fluid className="container">
                    <p className="titleLoyalty pb-2 border-bottom">Система лояльності</p>
                    <p className="nameLoyalty">Tech Kids Academy</p>
                    <Row className="img2 mx-auto justify-content-center">
                        <Col xs={12} md={5} className="imgLoyalty mb-5">
                            <Image src={LoyaltyImg} fluid />
                        </Col>
                        <Col xs={12} md={5} className="textInfoAbout mb-4">
                            <p className="textLoyalty">1. Для <span className="boldFontLoyalty">нових клієнтів</span> школи пробне заняття безкоштовне за умови купівлі абонементу у день проведення пробного заняття.<br></br>
                                2. Приведи друга та <span className="boldFontLoyalty">отримай знижку 10%</span> собі і другу, за умови придбання абонементу.<br></br>
                                3. Сімейна <span className="boldFontLoyalty">знижка 10%</span> на кожну дитину з однієї сім'ї.<br></br>
                                4. <span className="boldFontLoyalty">Знижка</span> 10%  надається військовослужбовцям <span className="boldFontLoyalty">ЗСУ</span>.
                            </p>
                            <p className="textLoyalty1"><span className="boldFontLoyalty">Акції та знижки не сумуються.</span></p>
                        </Col>
                    </Row>
                </Container>
                <img src={LineBGGreen} className="elementLoyalty3"></img>
                <img src={RedPartLogo} className="elementLoyalty4"></img>
                <Footer />
            </>
        )
    }
}