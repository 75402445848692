import React, { Component } from "react";
import "../styles/About.css";
import { Row, Col, Container, Image } from "react-bootstrap"
import Footer from "../Components/Footer";
import BannerAbout1 from '../assets/BannerAbout1.jpg';
import BannerAbout2 from '../assets/BannerAbout2.jpg';
import BannerAbout3 from '../assets/BannerAbout3.jpg';
import RedPartLogo from "../assets/RedPartLogo.png";
import CirculeOrange from "../assets/CirculeOrange.png";
import LineBGGreen from "../assets/LineBGGreen.png";
import LinesBlue from "../assets/LinesBlue.png";
import ButtonOnline from "../Components/ButtonOnline";

export default class About extends Component {
    render() {
        return (
            <>  
                <div style={{position: 'absolute', left: '0', bottom: '80px'}}>
                <ButtonOnline />
                </div>
                <Container fluid className="container">
                    <p className="titleNameAbout mt-3 mb-3 pb-2 border-bottom">TechKids Academy- місце, де діти реалізують свої ідеї для майбутніх успіхів</p>
                    <Row className="img2 mx-auto justify-content-center">
                        <Col xs={12} md={5} className="textInfoAbout">
                            <p><span className="boldtextAbout">TechKids Academy</span> - це сучасний навчальний заклад для дітей віком від 3 до 16  років який спеціалізується у напрямку програмування та робототехніки.<br></br><br></br>
                                Наша академія  пропонує унікальну можливість для кожної дитини, відкрити дивовижний світ програмування, творчості та технічних навичок.
                            </p>
                        </Col>
                        <Col xs={12} md={4} className="img1About">
                            <Image src={BannerAbout1} fluid />
                        </Col>
                    </Row>
                    <Row className="img2 mx-auto justify-content-center">
                        <Col xs={12} md={4} className="img2About">
                            <Image src={BannerAbout2} fluid /> 
                        </Col>
                        <Col xs={12} md={5} className="textInfoAbout mb-4">
                            <p><span className="boldtextAbout">TechKids Academy</span> використовує  STEAM - підхід у навчанні, що допомагає зробити процес засвоєння інформації більш ефективним та цікавим.<br></br><br></br>
                                Наші викладачі допоможуть дітям ознайомитись з різними середовищами, такими як Roblox, Minecraft, Scratch та Visual Studio Code.
                            </p>
                        </Col>
                    </Row>
                    <Row className="img2 mx-auto justify-content-center">
                        <Col xs={12} md={4} className="img2About2">
                            <Image src={BannerAbout2} fluid />
                        </Col>
                    </Row>
                    <Row className="img2 mx-auto justify-content-center">
                        <Col xs={12} md={5} className="textInfoAbout">
                            <p>Використання  ігрового підходу у вивченні основ програмування, дозволяє ефективно залучити учнів до навчання, та надихає їх на практичне застосування отриманих знань.<br></br><br></br>
                                Виконання завдань та набуття  результату кожного заняття, сприяє практичному розвитку навичок  соціалізації та роботі в команді, що є важливими аспектами у сучасному житті.
                            </p>
                        </Col>
                        <Col xs={12} md={4} className="img3About mb-5">
                            <Image src={BannerAbout3} fluid />
                        </Col>
                    </Row>
                    <p className="textAboutDown">Запрошуємо вашу дитину в захоплюючу подорож у світ IT технологій разом із TechKids Academy.</p>
                </Container>
                    <img src={RedPartLogo} className="elementAbout1"></img>
                    <img src={CirculeOrange} className="elementAbout2"></img>
                    <img src={LineBGGreen} className="elementAbout3"></img>
                    <img src={LinesBlue} className="elementAbout4"></img>
                <Footer />
            </>
        )
    }
}