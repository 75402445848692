import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import '../styles/NotificationModal.css'; // Импортируем CSS файл

const NotificationModal = () => {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);

  return (
    <Modal show={show} onHide={handleClose} centered className="custom-modal" backdrop={false} >
      <Modal.Header closeButton>
        <Modal.Title>Увага!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        З 29.07.24 по 11.08.24 в TechKids Academy підготовка до нового навчального сезону.
        З 12.08.24 починається запис у групи, чекаємо на вас.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Закрити
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NotificationModal;
