import React, { useRef, useState, Component } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import emailjs, { sendForm } from 'emailjs-com';
import "../styles/NewFormEmail.css";
import '../styles/Footer.css';

const Footer = () => {
    const form1 = useRef();
    const form2 = useRef();
    const sendEmail1 = (formData) => {
        handleClose1();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_ny6mx7o', data, '4upStKj7ORtxgJNce')
    };
    const sendEmail2 = (formData) => {
        handleClose1();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_1s0w5ve', data, '4upStKj7ORtxgJNce')
    };
    const sendEmail3 = (formData) => {
        handleClose1();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const message = formData.get('message');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            message: message,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_3o3voeb', data, '4upStKj7ORtxgJNce')
    };
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const handleSubmit1 = (event) => {
        event.preventDefault();
        const formData = new FormData(form1.current);
        const city = formData.get('city');
        if (city === 'Київ') {
            sendEmail1(formData);
        } else if (city === 'Рівне') {
            sendEmail2(formData);
        }
    };
    const handleSubmit2 = (event) => {
        event.preventDefault();
        const formData = new FormData(form2.current);
        const city = formData.get('city');
        sendEmail3(formData);
    };
    return (
        <footer className="text-center text-lg-start bg-body-primary text-muted">
            <section>
                <div className="container text-center text-md-start pt-2">
                    <div className="row mt-3">
                        <div className="col-md-4 col-lg-4 col-xl-4 mx-auto mb-2 text-center">
                            <p className='footer-text'>
                                <a href="tel:+380684043304">
                                    <i className="fas fa-phone me-3" />
                                    Київ: +38 (068) 404-33-04
                                </a>
                            </p>
                            <p className='footer-text'>
                                <a href="tel:+380979221700">
                                    <i className="fas fa-phone me-3" />
                                    Рівне: +38 (097) 922-17-00
                                </a>
                            </p>
                            <p className='footer-email-text'>
                                <a href="mailto:techkidsacademy2023@gmail.com">
                                    <i className="fas fa-envelope me-3" />
                                    techkidsacademy2023@gmail.com
                                </a>
                            </p>
                        </div>
                        <div className="col-md-4 col-lg-4 col-xl-4 mx-auto mb-2 text-center">
                            <p className="footer-text" >
                                <a href="#!" className="footer-text" onClick={handleShow1}>Зателефонуйте нам</a>
                                <Modal show={show1} onHide={handleClose1} backdrop={false} className='modalFormEmail'>
                                    <Modal.Header closeButton>
                                        <Modal.Title className="titleModal">Зателефонуйте нам</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form ref={form1} onSubmit={handleSubmit1} >
                                            <Form.Group controlId="formBasicName">
                                                <Form.Label className='textModal'>Ім'я та прізвище:</Form.Label>
                                            </Form.Group>
                                            <textarea className='textAreaForm' name="name" required title="Будь ласка, введіть ім'я та прізвище" />
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Label className='textModal mt-1'>Електронна адреса:</Form.Label>
                                            </Form.Group>
                                            <Form.Control className='textAreaForm' type="email" name="email" required plaintext title="Будь ласка, введіть електронну адресу" />
                                            <Form.Group controlId="formBasicPhone">
                                                <Form.Label className='textModal mt-2'>Номер телефону:</Form.Label>
                                            </Form.Group>
                                            <Form.Control className='textAreaForm ' type="tel" name="phone" pattern="[0-9]*" required plaintext title="Будь ласка, введіть номер телефону" />
                                            <Form.Group className="mb-3" controlId="formBasicCity">
                                                <Form.Label className='textModal mt-1 '>Ваше місто:</Form.Label>
                                                <select className='textAreaForm' name="city" required title="Будь ласка, оберіть місто">
                                                    <option value="">Оберіть місто</option>
                                                    <option value="Київ">Київ</option>
                                                    <option value="Рівне">Рівне</option>
                                                </select>
                                            </Form.Group>
                                            <div className="mb-3">
                                                <label htmlFor="dataProcessingAgreement">
                                                    <input
                                                        type="checkbox"
                                                        id="dataProcessingAgreement"
                                                        required
                                                    />
                                                    &nbsp;Натискаючи кнопку, я даю згоду на обробку персональних даних.
                                                </label>
                                            </div>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={handleClose1}>
                                                    Закрити
                                                </Button>
                                                <Button type="submit" variant="primary">
                                                    Відправити
                                                </Button>
                                            </Modal.Footer>
                                        </Form>
                                    </Modal.Body>
                                </Modal>
                            </p>
                            <p className="footer-text">
                                <a href="#!" className="footer-text" onClick={handleShow2}>Зв'язок з директором</a>
                                <Modal show={show2} onHide={handleClose2} backdrop={false} className='modalFormEmail'>
                                    <Modal.Header closeButton>
                                        <Modal.Title className="titleModal">Зв'язок з директором</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form ref={form2} onSubmit={handleSubmit2} >
                                            <Form.Group controlId="formBasicName">
                                                <Form.Label className='textModal'>Ім'я та прізвище:</Form.Label>
                                            </Form.Group>
                                            <textarea className='textAreaForm' name="name" required title="Будь ласка, введіть ім'я та прізвище" />
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Label className='textModal mt-1'>Електронна адреса:</Form.Label>
                                            </Form.Group>
                                            <Form.Control className='textAreaForm' type="email" name="email" required plaintext title="Будь ласка, введіть електронну адресу" />
                                            <Form.Group controlId="formBasicPhone">
                                                <Form.Label className='textModal mt-2'>Номер телефону:</Form.Label>
                                            </Form.Group>
                                            <Form.Control className='textAreaForm ' type="tel" name="phone" pattern="[0-9]*" required plaintext title="Будь ласка, введіть номер телефону" />
                                            <Form.Group className="mt-1" controlId="formBasicCity">
                                                <Form.Label className='textModal mt-1 '>Ваше місто:</Form.Label>
                                                <select className='textAreaForm' name="city" required title="Будь ласка, оберіть місто">
                                                    <option value="">Оберіть місто</option>
                                                    <option value="Київ">Київ</option>
                                                    <option value="Рівне">Рівне</option>
                                                </select>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicAge">
                                                <Form.Label className='textModal mt-1'>Повідомлення:</Form.Label>
                                            </Form.Group>
                                            <textarea className='textAreaFormMessage' name="message" required />
                                            <div className="mb-3">
                                                <label htmlFor="dataProcessingAgreement">
                                                    <input
                                                        type="checkbox"
                                                        id="dataProcessingAgreement"
                                                        required
                                                    />
                                                    &nbsp;Натискаючи кнопку, я даю згоду на обробку персональних даних.
                                                </label>
                                            </div>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={handleClose2}>
                                                    Закрити
                                                </Button>
                                                <Button type="submit" variant="primary" onClick={handleClose2}>
                                                    Відправити
                                                </Button>
                                            </Modal.Footer>
                                        </Form>
                                    </Modal.Body>
                                </Modal>
                            </p>
                            <p >
                                <a href="/LegalInfo" className="footer-text">Публічна оферта</a>
                            </p>
                        </div>
                        <div className="col-md-4 col-lg-4 col-xl-4 mx-auto mb-2 text-center">
                            <p className="footer-text">
                                <a href="/questions" className="footer-text">Часті запитання</a>
                            </p>
                            <p className='footer-text'><i className="fas fa-home me-3" /> Tech Kids Academy, 2024</p>
                            <p className='footer-text'><i className="fas fa-envelope me-3" />Усі права захищено</p>
                        </div>
                    </div>
                </div>
            </section>
        </footer>
    )

}

export default Footer;