import React, { Component } from "react";
import { Row, Col, Container, Image } from "react-bootstrap"
import CarouselBox from "../Components/CarouselBox";
import ButtonOnline from "../Components/ButtonOnline";
import Footer from "../Components/Footer";
import '../styles/Home.css';
import STEAMBanner from '../assets/SteamBanner.png';
import MinecraftHome from "../assets/MinecraftHome.png";
import RobloxHome from "../assets/RobloxHome.jpg";
import ScratchHome from "../assets/ScratchHome.png";
import LegoHome from "../assets/LegoHome.jpg";
import VisualHome from "../assets/VisualHome.jpg";
import KrainaMrii from "../assets/KrainaMrii.png";
import BrightSchool from "../assets/BrightSchool.jfif";
import KazkoviKids from "../assets/KazkoviKids.png";
import School309 from "../assets/School309.png";
import Logos329 from "../assets/Logos329.jpeg";
import OnlyLogo from "../assets/OnlyLogo.png";

export default class Home extends Component {
    render() {
        return (
            <>
                <CarouselBox />
                <ButtonOnline />
                <Container fluid className="container">
                    <div className="row">
                        <div className="col-sm-1 mb-3 mb-sm-0 mt-5 mx-auto totalCardSkills1">
                            <div className="parentSkills1">
                                <div className="cardSkills1 cardSkills1-1 ">
                                    <div className="glassSkills1" />
                                    <div className="contentSkills1">
                                        <span className="textSkills1"><span className="boltTextHome">Наша Мета:</span> Показати дітям складний світ через прості та цікаві приклади.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4 mb-3 mb-sm-0 mt-5 mx-auto totalCardSkills1">
                            <div className="parentSkills1">
                                <div className="cardSkills1">
                                    <div className="glassSkills1" />
                                    <div className="contentSkills1">
                                        <span className="textSkills1"><span className="boltTextHome">Наша Місія:</span> Сформувати корисний освітній простір для дітей та підлітків.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Row>
                        <img className="imageSTEAM" src={STEAMBanner} alt="STEAM Banner"></img>
                    </Row>
                    <p className="environmentTitle">В TechKids Academy ми створюємо неперевершений навчальний досвід, де діти реалізують свій творчий потенціал у поєднанні з набуттям практичних навичок.</p>
                    <p className="environmentTitle mb-5">Наші унікальні програми, базуються на таких середовищах як: </p>
                    <Row className="d-flex justify-content-center">
                        <div className="socialMDMi"><img className="environmentIMG" src={MinecraftHome}></img><p className="environmentText">Minecraft Education</p></div>
                        <div className="socialRo"><img className="environmentIMG" src={RobloxHome}></img><p className="environmentText">Roblox Studio</p></div>
                        <div className="socialMDSc"><img className="environmentIMG" src={ScratchHome}></img><p className="environmentText">Scratch3 та ScratchJr</p></div>
                        <div className="socialLe"><img className="environmentIMG" src={LegoHome}></img><p className="environmentText">LEGO Education</p></div>
                        <div className="socialMDVi mb-5"><img className="environmentIMG" src={VisualHome}></img><p className="environmentText">Visual Studio Code</p></div>
                    </Row>
                    <p className="skillsTitle">Ваша дитина разом з нами:</p>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-1 mb-3 mb-sm-0 mt-5 mx-auto totalCardSkills">
                                <div className="parentSkills">
                                    <div className="cardSkills">
                                        <div className="glassSkills" />
                                        <div className="contentSkills">
                                            <div className="logoWithTextHome">
                                                <img src={OnlyLogo} className="logoBeforeTextHome"></img>
                                                <span className="titleSkills">Hard skills:</span>
                                            </div>
                                            <span className="textSkills">&#9679; Опанує основи програмування;<br></br>
                                                &#9679; Дізнається що таке Game Design та створить власну гру;<br></br>
                                                &#9679; Оволодіє комп'ютерною грамотністю;<br></br>
                                                &#9679; Навчиться конструюванню та 3D моделюванню; <br></br>
                                                &#9679; Досліджуватиме світ навколо;
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4 mt-5 mb-5 mx-auto">
                                <div className="parentSkills">
                                    <div className="cardSkills">
                                        <div className="glassSkills" />
                                        <div className="contentSkills">
                                            <div className="logoWithTextHome">
                                                <img src={OnlyLogo} className="logoBeforeTextHome"></img>
                                                <span className="titleSkills">Soft skills:</span>
                                            </div>
                                            <span className="textSkills">&#9679; Розвине критичне мислення та креативність;<br></br>
                                                &#9679; Навчиться працювати в команді;<br></br>
                                                &#9679; Оволодіє навичками комунікабельності;<br></br>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="mt-5 partnersTitle">Наші партнери</p>
                        <div className="row mx-auto justify-content-center">
                            <img className="partnersHome" src={KrainaMrii}></img>
                            <img className="partnersHome" src={BrightSchool}></img>
                            <img className="partnersHome" src={KazkoviKids}></img>
                            <img className="partnersHome" src={School309}></img>
                            <img className="partnersHome mb-5" src={Logos329}></img>
                        </div>
                    </div>
                </Container>
                <Footer />
            </>
        )
    }
}