import React, { Component } from "react";
import '../styles/LegalInfo.css';
import Footer from "../Components/Footer";

export default class LegalInfo extends Component {
    render() {
        return (
            <>
                <div className="legal-container">
                    <p className="titleLegal pb-2 border-bottom">Публічна оферта</p>
                    <p className="titleLegaltitle"> ДОГОВІР ПРИЄДНАННЯ про надання освітніх послуг (надалі – Договір)</p>
                    <p className="textLegal">ФІЗИЧНА ОСОБА-ПІДПРИЄМЕЦЬ ФЕДОРОВА ОЛЬГА ОЛЕКСАНДРІВНА, яка діє на підставі Виписки з Єдиного державного реєстру юридичних осіб, фізичних осіб-підприємців та громадських формувань (номер запису 2010350000000473292 від 28.012.2023 року), надалі – TechKids Academy, з однієї сторони, та </p>
                    <p className="textLegal">ОСОБА, ЩО ПРИЄДНАЛАСЬ ДО ДОГОВОРУ, шляхом надання TechKids Academy Заяви про приєднання до Договору, зразок якої розміщений на
                        веб-сайті https://techkidsacademy.com.ua надалі – Замовник послуг, з іншої сторони, </p>
                    <p className="textLegal">спільно іменовані – Сторони, а кожна окремо – Сторона, враховуючи що:
                        <br></br>• до моменту укладення Договору з певним Замовником послуг цей Договір має статус оферти TechKids Academy;
                        <br></br>• цей договір розміщений в електронній формі на веб-сайті TechKids Academy https://techkidsacademy.com.ua та на інформаційному стенді при вході в TechKids Academy;
                        <br></br>• цей договір є договором приєднання згідно зі ст. 634 Цивільного кодексу України, а тому Замовник послуг не може пропонувати змінити умови цього Договору;
                        <br></br>• у випадку погодження Замовника послуг з умовами цього Договору, він має можливість укласти його з TechKids Academy шляхом приєднання до всіх його умов в цілому;
                        <br></br>• Договір не є публічним в розумінні ст. 633 Цивільного кодексу України,
                        разом – Сторони, а кожна окремо – Сторона, уклали цей договір, надалі – Договір, про таке.</p>
                    <p className="titleLegaltitle">1. ВИЗНАЧЕННЯ ТЕРМІНІВ</p>
                    <p className="textLegal">        <br></br>1.1. Позашкільна освіта – сукупність знань, умінь та навичок, що здобувають Діти в TechKids Academy шляхом вивчення відповідних освітніх курсів TechKids Academy. Позашкільна освіта Дитини Замовника послуг може здобуватися одночасно із здобуттям дошкільної або повної загальної середньої освіти.
                        <br></br>1.2. Дитина, Діти – особа(и) віком від 3 до 16 років.
                        <br></br>1.3.  Замовник послуг – дієздатна фізична особа, яка має Дитину або є законним представником Дитини та має намір отримувати освітні послуги для Дитини у сфері позашкільної освіти.
                        <br></br>1.4. Абонемент  – право отримувати освітні послуги у сфері позашкільної освіти в TechKids Academy на певну кількість занять та/або на певний період за певну ціну.
                        <br></br>1.5. Освітній курс – коло знань та умінь, що змістовно включає базові відомості з певної науки. Освітні курси в TechKids Academy мають ексклюзивні авторські програми, які розроблені та педагогічно адаптовані для дітей певного віку.
                        <br></br>1.6. Офіційний сайт TechKids Academy - https://techkidsacademy.com.ua</p>
                    <p className="titleLegaltitle">2. ПРЕДМЕТ ДОГОВОРУ</p>
                    <p className="textLegal"><br></br>2.1. TechKids Academy зобов'язується надавати освітні послуги у сфері позашкільної освіти, надалі – Послуги, для Дитини Замовника послуг, а Замовник послуг зобов'язується оплатитиTechKids Academy такі Послуги шляхом купівлі Абонемента на умовах і в строки, встановлені цим Договором.
                        <br></br>2.2. TechKids Academy надає Замовнику послуг Послуги на умовах цього Договору та з урахуванням Правил відвідування TechKids Academy, розроблених TechKids Academy
                        <br></br>2.3. Перелік Послуг із зазначенням назв освітніх курсів, видів Абонементів, їх вартості, а також Правила відвідуванняTechKids Academy – ця інформація розташована на Офіційному сайті TechKids Academy та/або Замовник послуг може ознайомитися з такою інформацією безпосередньо в TechKids Academy.
                        <br></br>2.4. День отримання заяви про приєднання до Договору від Замовника послуг є днем зарахування Дитини до TechKids Academy. Оплата Абонемента є підтвердженням бронювання місця для Дитини на освітньому курсі на визначений час занять. Дата закінчення дії Абонемента є датою останнього оплаченого заняття. Поновити навчання можливо лише за наявності вільних місць.</p>
                    <p className="titleLegaltitle">3. ПОРЯДОК УКЛАДЕННЯ ДОГОВОРУ</p>
                    <p className="textLegal"><br></br>3.1. Договір укладається між TechKids Academy і Замовником послуг шляхом приєднання Замовника послуг до всіх умов Договору в цілому в розумінні ст. 634 Цивільного кодексу України.
                        <br></br>3.2. Приєднання Замовника послуг до всіх умов Договору в цілому відбувається шляхом надання TechKids Academy заповненої Заяви про приєднання до Договору в письмовому вигляді (Додаток №1 до Договору), надалі – Заява, у двох примірниках, розміщеної на Офіційному сайті TechKids Academy. Замовник послуг може заповнити таку Заяву безпосередньо в TechKids Academy.
                        <br></br>3.3. Шляхом надання Заяви Замовник послуг підтверджує, що він:
                        <br></br>3.3.1. ознайомлений та погоджується з умовами Договору в повному обсязі без будь-яких виключень та/або обмежень;
                        <br></br>3.3.2. ознайомлений з переліком Послуг із зазначенням назв освітніх курсів, видів Абонементів, їх вартості, чинними на момент укладення Договору та розміщеними на Офіційному сайті TechKids Academy та/або безпосередньо в TechKids Academy;
                        <br></br>3.3.3. ознайомлений з Правилами відвідування TechKids Academy, чинними на момент укладення Договору та розміщеними на Офіційному сайті TechKids Academy та/або безпосередньо в TechKids Academy, а також зобов’язується їх виконувати.
                        <br></br>3.4. Договір вважається укладеним і вступає в силу з моменту прийняття TechKids Academy Заяви, при цьому датою укладання Договору вважається дата прийняття Заяви TechKids Academy, яка зазначена в Заяві.
                        <br></br>3.5. TechKids Academy вправі на власний розсуд відмовити Замовнику послуг у прийнятті Заяви.</p>
                    <p className="titleLegaltitle">4. ЦІНА ТА ПОРЯДОК РОЗРАХУНКІВ</p>
                    <p className="textLegal"><br></br>4.1. Загальна ціна Послуг за Договором складається із сум оплат, внесених Замовником послуг протягом дії цього Договору за Абонементи, відповідно до тарифів, вказаних на інформаційному стенді TechKids Academy та/або на Офіційному сайті TechKids Academy.
                        <br></br>4.2. Загальна ціна Договору залежить від кількості придбаних Абонементів на вивчення Дитиною освітнього(іх) курсу (ів).
                        <br></br>4.3. Встановлення ціни Послуг та їх оплата проводяться в національній валюті України – безготівковим шляхом.
                        <br></br>4.4. Послуги надаються Замовнику послуг виключно на попередній платній основі шляхом оплати в повному обсязі Абонемента на відповідну кількість занять та/або на відповідний період до дня початку відвідування занять Дитиною. Оплата наступного Абонемента проводиться на останньому оплаченому занятті попереднього Абонемента.
                        <br></br>4.5. Оплата Абонемента є підтвердженням заявки на вивчення Дитиною відповідного освітнього курсу та бронювання місця для Дитини на освітньому курсі на визначений час занять.
                        <br></br>4.6.  У виключних випадках TechKids Academy може допустити Дитину до навчання без оплати за наступний Абонемент за умови, якщо така оплата буде здійснена до кінця дня, коли відбулося перше неоплачене заняття. При затриманні оплати TechKids Academy залишає за собою право відмовити в наданні Послуг Замовнику послуг, що не звільняє останнього від виконання своїх фінансових зобов’язань перед TechKids Academy.
                        <br></br>4.7. За несвоєчасно внесену оплату може нараховуватися пеня у розмірі 1 (одного) відсотка від суми заборгованості за кожний прострочений день, починаючи з дати відвідування неоплаченого заняття Дитиною.
                        <br></br> 4.8. У зв’язку з порушенням умов Договору зі сторони Замовника послуг TechKids Academy має право достроково розірвати Договір, при цьому оплата, внесена Замовником послуг, не повертається, а зараховується як штрафні санкції.
                        <br></br>4.9. Сплата фінансових санкцій не звільняє винну Сторону від подальшого виконання зобов’язань за цим Договором.</p>
                    <p className="titleLegaltitle">1. ПРАВА ТА ОБОВ'ЯЗКИ TechKids Academy</p>
                    <p className="textLegal">        <br></br>1.1. TechKids Academy зобов'язана:
                        <br></br>1.1.1. До початку надання Послуг ознайомити Замовника послуг з вимогами техніки безпеки в TechKids Academy, а також з Правилами відвідування TechKids Academy.
                        <br></br>1.1.2. Організувати позашкільну освіту Дитини відповідно до її віку, індивідуальних особливостей, змісту програми обраного(их) освітнього(іх) курсу(ів).
                        <br></br>1.1.3. Розвивати творчі здібності й інтереси Дитини.
                        <br></br>1.1.4. Здійснювати надання Послуг із застосуванням індивідуального підходу до Дитини.
                        <br></br>1.1.5. Попередити Замовника послуг про відміну або перенесення заняття завчасно. TechKids Academy не несе відповідальності за несвоєчасне попередження у випадку, якщо Замовником послуг було надано неактуальні контактні дані.
                        <br></br>1.1.6. Надавати консультативну допомогу батькам Дитини у вихованні й навчанні Дитини.
                        <br></br>1.1.7. Навчати Дитину із залученням кваліфікованих кадрів та використанням сучасних педагогічних технологій.
                        <br></br> 1.1.8. Виконувати санітарні та пожежні норми для позашкільних установ короткострокового перебування.
                        <br></br>1.1.9. Не залишати без нагляду Дитину під час освітньо-виховного процесу.

                        <br></br>1.2. TechKids Academy має право:
                        <br></br>1.2.1. Зарахувати Дитину до відповідної вікової групи з метою вивчення певного освітнього курсу.
                        <br></br>1.2.2.  Корегувати розклад занять.
                        <br></br>1.2.3. Не допускати до TechKids Academy Замовника послуг чи інших осіб у стані алкогольного або наркотичного сп'яніння.
                        <br></br>1.2.4. Не пускати батьків та інших близьких родичів Дитини на заняття, окрім відкритих занять.
                        <br></br>1.2.5. У випадку відсутності Дитини на заняттях без завчасного попередження не надати можливість для відпрацювання або переносу пропущених занять. При цьому вартість пропущеного(их)  заняття(ть) не повертається, а зараховується як штрафні санкції.
                        <br></br>1.2.6. Призупинити надання Послуг Дитині та не бронювати місце Дитині в групі при несвоєчасному виконанні Замовником своїх фінансових зобов’язань перед TechKids Academy.
                        <br></br>1.2.7. TechKids Academy залишає за собою право виключити Дитину з групи, якщо її поведінка загрожує психічному та/або фізичному здоров’ю інших дітей у групі. У такому випадку Послуги за цим Договором вважаються наданими в повному об’ємі, оплата за невикористані заняття не повертається, а зараховується як штрафні санкції.
                        <br></br>1.2.8. Відрахувати Дитину з TechKids Academy при невиконанні батьками умов і правил відвідування TechKids Academy.
                        <br></br>1.2.9. Не приймати до відвідування занять Дитину з явними ознаками нездужання.
                        <br></br>1.2.10. Проводити відеонагляд у приміщеннях TechKids Academy.
                        <br></br>1.2.11. Проводити фото- та відеозйомку у приміщеннях TehKids Academy, використовувати та розміщувати фото- та відеоконтент із зображенням Дитини на публічних ресурсах у рекламних та інформаційних цілях.
                        <br></br>1.2.12. В односторонньому порядку змінювати умови Договору, перелік Послуг із зазначенням назв освітніх курсів, види Абонементів, їх вартість, Правила відвідування TechKids Academy шляхом розміщення змінених умов на своєму Офіційному сайті та/або безпосередньо в TechKids Academy.
                        <br></br>1.2.13. Вимагати від Замовника послуг відшкодовувати збитки TechKids Academy, які сталися з вини Дитини та/або законних представників Дитини та/або їх довірених осіб (няні, водія тощо).</p>
                    <p className="titleLegaltitle">2. ПРАВА ТА ОБОВ'ЯЗКИ ЗАМОВНИКА ПОСЛУГ</p>
                    <p className="textLegal"><br></br>6.1. Замовник послуг зобов’язаний:
                        <br></br>6.1.1.  Оплачувати Послуги згідно з умовами Договору.
                        <br></br>6.1.2. Вносити достовірну інформацію в Заяву. У разі зміни даних, зазначених в Заяві, Замовник послуг надає протягом п’яти днів нову інформацію.
                        <br></br>6.1.3. Дотримуватись положень Договору та Правил відвідування TechKids Academy.
                        <br></br>6.1.4. Сповіщати адміністрацію TechKids Academy про відсутність Дитини на плановому занятті або на відпрацьованому занятті не пізніше ніж за один день до дня проведення відповідного заняття.
                        <br></br>6.1.5. Приводити Дитину до TechKids Academy і забирати її зі TechKids Academy особисто або визначити довірених осіб, які матимуть на це право за умови попереднього письмового повідомлення TechKids Academy про таких осіб.
                        <br></br>6.1.6. Наперед повідомляти TechKids Academy про відсутність Дитини, а також про бажання перенести заняття з врахуванням Правил відвідування TechKids Academy.
                        <br></br>6.1.7. Взаємодіяти із TechKids Academy щодо виховання й навчання Дитини.
                        <br></br>6.1.8. Виконувати законні вимоги TechKids Academy, педагогів та адміністраторів TechKids Academy.
                        <br></br>6.1.9. Невідкладно відшкодовувати збитки TechKids Academy, які сталися з вини Дитини та/або законних представників Дитини та/або їх довірених осіб (няні, водія тощо).

                        <br></br>6.2. Замовник послуг має право:
                        <br></br>6.2.1. Користуватись Послугами згідно з умовами Договору.
                        <br></br>6.2.2. Вимагати від TechKids Academy виконання умов Договору.
                        <br></br>6.2.3. Знайомитися з умовами здійснення навчально-виховного процесу та порядку надання Послуг в TechKids Academy.
                        <br></br>6.2.4. Вносити пропозиції щодо покращення роботи TechKids Academy.
                        <br></br>6.2.5. Брати участь у заходах TechKids Academy.</p>
                    <p className="titleLegaltitle">7. ВІДПОВІДАЛЬНІСТЬ СТОРІН</p>
                    <p className="textLegal"><br></br>7.1. У випадку порушення своїх зобов’язань за цим Договором Сторони несуть відповідальність, визначену цим Договором та чинним в Україні законодавством. Порушенням зобов’язання є його невиконання або неналежне виконання, тобто виконання з порушенням умов, визначених змістом зобов’язання.
                        <br></br>7.2.  Замовник послуг несе всю відповідальність за достовірність відомостей, вказаних ним у Заяві. У випадку якщо Замовник послуг не зазначив або неточно зазначив дані у Заяві, TechKids Academy не відповідає за збитки Замовника послуг, понесені в результаті відмови в поверненні передоплати, наданні Послуг, організації відпрацювання занять та/або здійснення інших дій, внаслідок неможливості правильної ідентифікації Замовника послуг.
                        <br></br>7.3. Сторони не несуть відповідальність за порушення своїх зобов’язань за цим Договором, якщо воно сталося не з їх вини. Сторона вважається не винуватою, якщо вона доведе, що вжила всіх залежних від неї заходів для належного виконання зобов’язання.
                        <br></br>7.4. За порушення Замовником послуг умов цього Договору TechKids Academy залишає за собою право або не допустити Дитину Замовника послуг до відвідування занять у TechKids Academy, або заборонити входити на територію TechKids Academy особам, які порушили умови цього Договору. При цьому Послуги за цим Договором вважаються наданими в повному обсязі, гроші, сплачені за Послуги такому Замовнику послуг, не компенсуються, а зараховуються як штрафні санкції. Рішення про недопущення Дитини до занять або заборону входу Замовника і його довіреним особам приймає адміністрація TechKids Academy. </p>
                    <p className="titleLegaltitle">8. СТРОК ДІЇ ДОГОВОРУ, ВНЕСЕННЯ ЗМІН ТА УМОВИ ЙОГО РОЗІРВАННЯ </p>
                    <p className="textLegal">        <br></br>8.1. Договір набирає чинності з моменту прийняття TechKids Academy Заяви та діє до 31 серпня 2024 року.
                        <br></br>8.2. Якщо будь-яка із Сторін не заявить іншій Стороні за 30 (тридцять) календарних днів до кінця строку дії Договору про відмову від пролонгації його дії, Договір вважається пролонгованим на наступний рік на таких же умовах. Наступні пролонгації дії Договору відбуваються таким же чином, їх кількість не обмежується.
                        <br></br>8.3. Закінчення строку дії цього Договору не звільняє Сторони від виконання зобов’язань за Договором, у тому числі виконання зобов’язань з оплати Послуг, а також не звільняє Сторони від відповідальності за його порушення, яке мало місце протягом дії Договору.
                        <br></br>8.4. TechKids Academy має право в односторонньому порядку змінювати Договір шляхом розміщення змінених умов на своєму Офіційному сайті або повідомлення про зміни Замовника послуг шляхом надіслання писемного або електронного повідомлення.
                        <br></br>8.5. Зміни до Договору набувають чинності з наступного дня з дати оприлюднення TechKids Academy інформації про ці зміни або з дати набрання змінами чинності, якщо така дата зазначена в оприлюдненій інформації.
                        <br></br>8.6. Будь-які зміни Договору з моменту набрання ним чинності поширюються на всіх Замовників послуг, які приєдналися до Договору, у тому числі, на тих, хто приєднався до Договору раніше дати набрання чинності змін до Договору.
                        <br></br>8.7. TechKids Academy не несе будь-якої відповідальності у випадку, коли інформація про зміну Договору, що оприлюднена в порядку, установленому цим Договором, не була отримана та/або вивчена та/або правильно зрозуміла Замовником послуг.
                        <br></br>8.8. Договір може бути достроково розірвано у таких випадках:
                        <br></br>8.8.1. За ініціативою Замовника послуг у разі небажання продовжувати отримувати Послуги після закінчення дії оплаченого Абонемента.
                        <br></br>8.8.2. За рішенням TechKids Academy у разі невиконання Замовником послуг своїх зобов'язань за цим Договором.
                        <br></br>8.8.3. На інших підставах, передбачених цим Договором та/або чинним законодавством України.
                        <br></br>8.9. До моменту розірвання Договору Сторони зобов’язані провести повний взаєморозрахунок.</p>
                    <p className="titleLegaltitle">9. ВИРІШЕННЯ СУПЕРЕЧОК</p>
                    <p className="textLegal">        <br></br>9.1. Сторони домовилися, що всі суперечки та розбіжності, які виникають між Сторонами за цим Договором або у зв'язку з його виконанням, вирішуються шляхом переговорів.
                        <br></br>9.2. У разі недосягнення згоди між Сторонами щодо спірних питань, такий спір підлягає розгляду в господарському суді в порядку, встановленому чинним законодавством України.</p>
                    <p className="titleLegaltitle">10. ІНШІ ПОЛОЖЕННЯ</p>
                    <p className="textLegal">        <br></br>10.1. Сторони несуть відповідальність за правильність вказаних реквізитів та стверджують, що мають всі передбачені чинним законодавством України повноваження на підписання Договору.
                        <br></br>10.2. Сторони зобов’язуються протягом трьох днів у письмовій формі повідомляти іншу Сторону про зміну реквізитів, адрес шляхом надіслання повідомлення, а в разі неповідомлення – несуть ризик настання пов’язаних із цим несприятливих наслідків.
                        <br></br>10.3. Якщо одне з положень Договору є або стане незаконним, законність Договору в цілому не повинна бути під сумнівом. Незаконне положення має бути замінено статтею, яка найповніше передає бажання Сторін Договору та відповідає чинному законодавству України.
                        <br></br>10.4. Сторони погодились, що їх персональні дані, які стали їм відомі у зв’язку з укладанням Договору, можуть включатися до баз персональних даних Сторін. Підписуючи цей Договір, Сторони дають згоду (дозвіл) на обробку їх персональних даних, з метою підтвердження повноважень суб’єкта на укладання, зміну та розірвання Договору, забезпечення реалізації адміністративно-правових і податкових відносин, відносин у сфері бухгалтерського обліку та статистики, а також для забезпечення реалізації інших передбачених законодавством відносин. Представники Сторін підписанням цього Договору підтверджують, що вони повідомлені про свої права відповідно до ст. 8 Закону України «Про захист персональних даних».
                        <br></br>10.5.  Замовник послуг надає згоду на участь Дитини в фото- та відеозйомках під час перебування Дитини у TechKids Academy, на використання та розміщення фото- та відеозйомок із зображенням Дитини на публічних ресурсах у рекламних та інформаційних цілях.
                        <br></br>10.6. У випадках, не передбачених цим Договором, Сторони керуються чинним законодавством України.
                        <br></br>10.7. Цей Договір укладений при повному розумінні Сторонами його умов та термінології українською мовою у двох тотожних примірниках, які мають однакову юридичну силу, по одному для кожної Сторони.</p>
                    <p className="titleLegaltitle">АДРЕСА ТА РЕКВІЗИТИ TechKids Academy</p>
                    <p className="textLegal">
                        <p className="FOP">ФІЗИЧНА ОСОБА-ПІДПРИЄМЕЦЬ
                            <br></br>ФЕДОРОВА ОЛЬГА ОЛЕКСАНДРІВНА
                        </p>
                        Юридична адреса: м.Київ, вул. Анни Ахматової, 22
                        <br></br>Поштова адреса: 02081
                        <br></br>Код ЄДРПОУ 1521700968
                        <br></br>Виписка з Єдиного державного реєстру юридичних осіб та фізичних осіб-підприємців від 28.12.2023 року (номер запису 2010350000000473292 від 28.12.2023)
                        <br></br>П/рUA723515330000026005045910500 в ПриватБанк, МФО 305299
                        ФЕДОРОВА ОЛЬГА ОЛЕКСАНДРІВНА
                    </p>
                </div >
                <Footer />
            </>
        )
    }
}