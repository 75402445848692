import React, { Component } from "react";
import { Container } from "react-bootstrap";
import "../styles/MiniKindergarten.css";
import Footer from "../Components/Footer";
import RedPartLogo from "../assets/RedPartLogo.png";
import CirculeOrange from "../assets/CirculeOrange.png";
import LineBGGreen from "../assets/LineBGGreen.png";
import LinesBlue from "../assets/LinesBlue.png";
import KinderIMGPage from "../assets/KinderIMGPage.jpg";

export default class MiniKindergarten extends Component {
    render() {
        return (
            <>
                <Container fluid className="container">
                    <p className="titleKinder mt-3 mb-3 pb-2 border-bottom">Міні-садок TechKids Academy</p>
                    <img className=" imgKinder mb-5" src={KinderIMGPage}></img>
                    <p className="textKinder mb-5"><span className="boldTextKinder">Міні-садок TechKids Academy</span> - це невід'ємна частина нашого навчального середовища, де дбайливі вихователі створюють комфортну та безпечну атмосферу для дітей віком від 3 до 6 років.<br></br><br></br>
                        Заняття в міні-садку проводяться з 8:30 до 13:30, де діти розвивають соціальні навички, творчість та базові знання за допомогою ігор, малювання, конструювання та іншій веселій та пізнавальній діяльності, готуючись до подальшого навчання в закладах освіти.<br></br><br></br>
                        В дитячому садку присутнє збалансоване харчування яке враховує індивідуальні потреби кожної дитини.<br></br><br></br>
                        &#9679; Кількість дітей в групі:  до 6 дітей<br></br>
                        &#9679; Тривалість одного дня:  5 годин<br></br>
                        &#9679; Дні робити садочка: з Пн по Пт<br></br>
                    </p>
                </Container>
                <img src={RedPartLogo} className="elementKinder1"></img>
                <img src={CirculeOrange} className="elementKinder2"></img>
                <img src={LineBGGreen} className="elementKinder3"></img>
                <img src={LinesBlue} className="elementKinder4"></img>
                <Footer />
            </>
        )
    }
}