import React, { Component } from "react";
import { Container } from "react-bootstrap"
import "../styles/RivneLocation.css";
import Footer from "../Components/Footer.js";
import LineBGBlue from "../assets/LineBGBlue.png";
import OrangePartLogo from "../assets/OrangePartLogo.png";
import ButtonOnline from "../Components/ButtonOnline.js";

export default class RivneLocation extends Component {
    render() {
        return (
            <>
            <div style={{position: 'absolute', left: '0', bottom: '80px'}}>
                <ButtonOnline />
                </div>
                <Container fluid className="container">
                    <p className="titleRivneLoc">Наша філія у Рівному</p>
                    <div className="map-section mb-5">
                        <div className="gmap-frame">
                            <iframe id="gmap_canvas" width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=%D0%A0%D1%96%D0%B2%D0%BD%D0%B5%2033000,%20%D0%B2%D1%83%D0%BB.%20%D0%A1%D0%BE%D0%B1%D0%BE%D1%80%D0%BD%D0%B0,%20192%20Tech%20Kids%20Academy+(TechKids%20Academy)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">TechKids Academy</a></iframe>
                        </div>
                    </div>
                </Container>
                <img src={LineBGBlue} className="elementRivneLoc1"></img>
                <img src={OrangePartLogo} className="elementRivneLoc2"></img>
                <Footer />
            </>
        );
    }
}
