import React, { useRef, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import emailjs from 'emailjs-com';
import "../styles/NewFormEmail.css";

const SelectionOfCourses = () => {
    const form = useRef();
    const sendEmail1 = (formData) => {
        handleClose1();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const age = formData.get('age');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            age: age,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_7tvsiek', data, '4upStKj7ORtxgJNce')
    };
    const sendEmail2 = (formData) => {
        handleClose1();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const age = formData.get('age');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            age: age,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_6cpolds', data, '4upStKj7ORtxgJNce')
    };
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const handleSubmit1 = (event) => {
        event.preventDefault();
        const formData = new FormData(form.current);
        const city = formData.get('city');
        if (city === 'Київ') {
            sendEmail1(formData);
        } else if (city === 'Рівне') {
            sendEmail2(formData);
        }
    };
    return (
        <>
            <div className="buttonContainer">
                <button className="buttonSingCourse" onClick={handleShow1}>Обрати курс</button>
            </div>
            <Modal show={show1} onHide={handleClose1} backdrop={false} className='modalFormEmail'>
                <Modal.Header closeButton>
                    <Modal.Title className="titleModal">Обрати курс</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form ref={form} onSubmit={handleSubmit1} >
                        <Form.Group className="" controlId="formBasicName">
                            <Form.Label className='textModal'>Ім'я та прізвище:</Form.Label>
                        </Form.Group>
                        <textarea className='textAreaForm' name="name" required title="Будь ласка, введіть ім'я та прізвище" />
                        <Form.Group className="" controlId="formBasicEmail">
                            <Form.Label className='textModal mt-1'>Електронна адреса:</Form.Label>
                        </Form.Group>
                        <Form.Control className='textAreaForm' type="email" name="email" required plaintext title="Будь ласка, введіть електронну адресу" />
                        <Form.Group className="" controlId="formBasicPhone">
                            <Form.Label className='textModal mt-2'>Номер телефону:</Form.Label>
                        </Form.Group>
                        <Form.Control className='textAreaForm ' type="tel" name="phone" pattern="[0-9]*" required plaintext title="Будь ласка, введіть номер телефону" />
                        <Form.Group className="" controlId="formBasicAge">
                            <Form.Label className='textModal mt-2'>Вік дитини:</Form.Label>
                        </Form.Group>
                        <Form.Control className='textAreaForm' name="age" pattern="[0-9]*" required plaintext title="Будь ласка, введіть вік дитини"/>
                        <Form.Group className="mb-3" controlId="formBasicCity">
                            <Form.Label className='textModal mt-1 '>Ваше місто:</Form.Label>
                            <select className='textAreaForm' name="city" required title="Будь ласка, оберіть місто">
                                <option value="">Оберіть місто</option>
                                <option value="Київ">Київ</option>
                                <option value="Рівне">Рівне</option>
                            </select>
                        </Form.Group>
                        <div className="mb-3">
                            <label htmlFor="dataProcessingAgreement">
                                <input
                                    type="checkbox"
                                    id="dataProcessingAgreement"
                                    required
                                />
                                &nbsp;Натискаючи кнопку, я даю згоду на обробку персональних даних.
                            </label>
                        </div>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose1}>
                                Закрити
                            </Button>
                            <Button type="submit" variant="primary">
                                Відправити
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SelectionOfCourses;